export const REGISTER_CLIENT = 'REGISTER_CLIENT';
export const UPDATE_CLIENTE = 'UPDATE_CLIENTE';
export const CHANGE_OPTION_MENU_DASHBOARD = 'CHANGE_OPTION_MENU_DASHBOARD';
export const LOAD_OPTION_MENU_DASHBOARD = 'LOAD_OPTION_MENU_DASHBOARD'

export const LOAD_OPEN_SERVICE_FORM = 'LOAD_OPEN_SERVICE_FORM';
export const UPDATE_OPEN_SERVICE_FORM = 'UPDATE_OPEN_SERVICE_FORM';

export const REGISTER_USER = 'REGISTER_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const LOAD_USER = 'LOAD_USER';


export const LOGIN_USER = 'LOGIN_USER,' 
export const GET_USER_FROM_LOCAL_STORAGE = 'GET_USER_FROM_LOCAL_STORAGE,' 
export const LOGOUT_USER = 'LOGOUT_USER'
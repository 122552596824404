import './Report.scss';

const Report = () => {

    return (
        <div className="report-container">
            <h1>Reportes</h1>
            <div className="report-container__image-box">
                <h2>Estamos trabajando en ello...</h2>
                <img src="/images/working_in_that.png" alt="Trabajando en ello" />
            </div>
        </div>
    )
}

export default Report